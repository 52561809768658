import React from 'react';
import Admin from "../components/AdminPanel/Admin";

const AdminPanel = () => {
    return (
        <>
            <Admin/>
        </>
    );
};

export default AdminPanel;