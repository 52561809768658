import React from 'react';
import TermsComp from "../components/TermsPrivacyCookie/TermsComp";

const Terms = () => {
    return (
        <>
            <TermsComp/>
        </>
    );
};

export default Terms;