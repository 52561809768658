import React from 'react';
import ContactsComp from "../components/Contacts/ContactsComp";


const Contacts = () => {
    return (
        <div>
            <ContactsComp/>
        </div>
    );
};

export default Contacts;