import React from 'react';
import "./AboutUs.css"
import Title from "../ui/Title/Title";
import Button from "../ui/Button/Button";
import {Link} from "react-router-dom";

const AboutText = () => {
    return (
        <div className="about__text">
            <Title fontSize="48px">About us</Title>
            <p>Artallai is the best possible group of pioneers in artificial intelligence art generation who have come
                together to help you participate in this exciting new world. Our main mission is to developed a platform
                to help you buy and sell your favorite art pieces generated by AI using payment methods that suit your
                needs.</p>
            <Link to="/products">
                <Button>Catalog</Button>
            </Link>
        </div>
    );
};

export default AboutText;