import React from 'react';
import Title from "../ui/Title/Title";
import "./TermsAndPrivacy.css"

const TermsComp = () => {
    return (
        <div className="tandcs">
            <Title>Cookies Notice</Title>

            <section className="terms__group">
                <h1>Last Updated: August 14, 2024</h1>
                <p>Welcome to artallai.com! We use cookies and similar technologies to improve your browsing experience and enhance our services. This Cookie Notice (the "Notice") explains the types of cookies we use, their purposes, and how you can manage your preferences. By continuing to browse our site, you agree to the terms outlined in this Notice. Please read it carefully to understand how we use cookies. If you have any questions, feel free to contact us: <br/> <br/> Artallai s.r.o.
Registration No 21807531 <br/>
Legal address: Rybná 716/24, Staré Město, 110 00 Prague, Czech Republic <br/>
Email: info@artallai.com </p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>What are Cookies?</Title>
                <p>Cookies are small text files stored on your device when you visit a website. They store data about your interactions, allowing the site to remember your preferences and enhance your experience on future visits.<br/>
Cookies can do a lot, like storing login details, language preferences, and other settings to make your visit more personalized. They also help run essential site features and allow us to analyze how you interact with our content to improve our services. <br/><br/>

Cookies might be set by the site you’re visiting (first-party cookies) or by third-party services we use to provide additional content, run analytics, or display advertisements. These third-party cookies help track user activity across different sites to deliver personalized ads and gather insights into user behavior.<br/>
To learn more about cookies, you can visit www.aboutcookies.org or www.allaboutcookies.org.
</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>Types of Cookies We Use</Title>
                <p>Our website uses both essential and non-essential cookies to provide you with the best possible browsing experience. Here’s a quick overview:</p>
                <ul className='terms__list'>
                    <li><strong>Essential Cookies (Strictly Necessary Cookies):</strong> These cookies are crucial for our website to function properly. They manage security, network operations, and accessibility, such as secure logins and page navigation. Because they are essential, these cookies do not require your consent to be stored on your device.</li><br/>
                    <li><strong>Non-Essential Cookies:</strong> While not essential for the website's basic operations, non-essential cookies greatly improve your user experience. These include:
                        <ul className='terms__list'>
                        <br/>
                            <li><strong>Performance and Functionality Cookies:</strong> These cookies improve the performance and functionality of our website by remembering your preferences and settings. Disabling them may result in a less personalized experience.</li>
                            <li><strong>Analytics Cookies:</strong> These cookies collect data on how visitors interact with our site, such as pages visited and time spent on the site. This information helps us understand user behavior and make improvements to the website.</li>
                            <li><strong>Advertising Cookies:</strong> These cookies make advertising more relevant to your interests by ensuring that you don’t see the same ad too often and by selecting ads based on your preferences. They also help us and our partners deliver personalized ads.</li>
                        </ul>
                    </li>
                </ul>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>Managing Your Cookie Preferences</Title>
                <p>You have control over how cookies are managed on your device. Most browsers offer settings that let you decide whether to accept cookies. Below are links to instructions for popular browsers on how to adjust your cookie settings:</p>
                <ul className="terms__list">
                    <li>Google Chrome</li>
                    <li>Internet Explorer</li>
                    <li>Firefox</li>
                    <li>Safari</li>
                    <li>Safari Mobile</li>
                    <li>Opera</li>
                </ul>
                <p>For other browsers, please visit the developer's website. To opt out of being tracked by Google Analytics across all websites, visit http://tools.google.com/dlpage/gaoptout.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>Cookies and Your Personal Data</Title>
                <p>Cookies may collect personal data such as your IP address, browsing behavior, and device information. This data can sometimes be used to create a profile of your interests and activities, helping to personalize your experience and deliver relevant content and services.
We are committed to protecting any personal data collected through cookies. Our data handling practices comply with relevant data protection laws, including the General Data Protection Regulation (GDPR). We ensure that your data is processed securely and responsibly. For more details on how we handle your personal data, please see our Privacy Notice available on our website.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>Cookie Retention Period</Title>
                <p>Cookies have different lifespans. Session cookies are temporary and are deleted when you close your browser. Persistent cookies remain on your device until they expire or are manually deleted. We make sure cookies are active only as long as needed to fulfill their purpose, usually not exceeding 2 years.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'} id='disclaimers'>Updates to This Notice</Title>
                <p>We may update this Cookie Notice periodically to reflect changes in our practices or for legal reasons. Please check this Notice regularly to stay informed about our use of cookies. If there are significant changes, we’ll let you know through our website or by direct notification if necessary.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>Contact Us</Title>
                <p>We’re committed to being transparent about our use of cookies. If you have any questions or need further information, don’t hesitate to reach out to us at info@artallai.com. Our team is here to help!</p>
            </section>

        </div>
    );
};

export default TermsComp;