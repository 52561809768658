import React from 'react';

const Error = () => {
    return (
        <div>
            <h1 style={{fontSize: 42, margin: "0 0 20px 20px"}}>Page Not Found</h1>
        </div>
    );
};

export default Error;