import React from 'react';
import Title from "../ui/Title/Title";
import "./TermsAndPrivacy.css"

const TermsComp = () => {
    return (
        <div className="tandcs">
            <Title>Terms and Conditions</Title>

            <section className="terms__group">
                <h1>Last Updated: August 14, 2024</h1>
                <p>Welcome to https://artallai.com (the “Website”), which is operated by Artallai s.r.o., a company registered in the Czech Republic, company number 21807531, registered address Rybná 716/24, Staré Město, 110 00 Praha 1 ("we", "us", "our", “Company” or "Artallai"). These Terms and Conditions (the “Terms”, “Agreement”) apply to the use and accessing to the Website, purchase of any products or services with us via Website (the “Platform”) and govern contractual relationship between Artallai and user of our services (“you”, “your”, “user”, “customer”).</p>
            </section>

            <section className="terms__group">
                <p>You shall read these Terms before you access to the Website or continue using or obtaining any content, materials, information, products or services represented on the Website. If you do not accept or do not agree with Terms, then you shall not use our Website or any of our services.</p>
            </section>

            <section className="terms__group">
                <p>By accessing, using, or making a purchase through our Website, you agree to abide by these Terms, our Privacy Policy, and any other additional terms, conditions, and policies referenced here or available via hyperlinks on the Website (collectively referred to as the “Terms of Use”).</p>
            </section>

            <section className="terms__group">
                <p>Please review our Privacy Policy concerning collection and/or processing data from users of the Website before you continue using our Website. The company is not responsible for any content and privacy practices of other websites that may be linked from our Website.</p>
            </section>

            <section className="terms__group">
                <p>Should you have any questions or concerns about these Terms or Terms of Use, please contact us at info@artallai.com.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>1. Modifications</Title>
                <p>1.1 The Company reserves the right to periodically modify, suspend, or discontinue the Website, Services, and/or their content at its sole discretion and without prior notice. It is your responsibility to review these Terms regularly. We will update the "Last Updated" date at the top of these Terms to reflect the effective date of any changes. Your continued use or access to our Website after such changes indicates your acceptance of the revised Terms. If you disagree with any provision or become dissatisfied with the Website, your sole option is to cease using the Website immediately.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>2. Services</Title>
                <p>2.1 This Website is representing the Platform where you may buy or sell AI generated pieces of art (“Digital Items”) by means of entering into contract with us as defined herein.</p>
                <p>2.2 The Digital Items are intended solely for your non commercial use, have no monetary value, and cannot be redeemed for cash, sold, or transferred, except as explicitly stated. You are granted a license to use these Digital Items rather than owning them outright. You do not have ownership or proprietary rights over these Digital Items.</p>
                <p>2.3 The Company reserves the right to repurchase or exchange Digital Items from you in exchange for real life currency or other Digital Items, depending on the conditions provided in the relevant menu on the Website, before confirming the transaction.</p>
                <p>2.4 If your account is terminated, all Digital Items will be deleted or removed and will not be recoverable, retrievable, or refundable.</p>
                <p>2.5 When using our Services you may also engage in transaction with us for selling your own Digital Items (“Your Content”) to us. When offering Your Content for purchase you determining the pricing at your sole discretion. We reserve the right at our sole discretion to accept or reject your offer or give you our counteroffer with our proposed price. The Company has the right to refuse to accept Your Content for any reason or no reason at all.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>3. Your Content</Title>
                <p>3.1 When you submitting or selling Your Content to Artallai, you grant Artallai a worldwide, non-exclusive, sub-licensable, right and license to reproduce, print, distribute, adapt, prepare derivative works from or incorporating, publicly perform and display (through all media now known or hereafter created), sublicense, sell, advertise and market any Your Content uploaded by you and accepted by Artallai. You also give permission to Artallai to add, modify, or remove information related to Your Content in order to manage and license such content. You are granting Artallai the non-exclusive right to sub-license and use Your Content.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>4. Eligibility Criteria</Title>
                <p>4.1 You are allowed to use our Website and Services only for legal purposes and in accordance with all relevant laws and regulations. Access is restricted to individuals who are at least 18 years old. If you reach the legal age in your jurisdiction or place of residence at a later date, that age will apply.</p>
                <p>4.2 By accessing and/or using our Website and Services, you affirm that you are legally able to enter into and form contracts under applicable laws.</p>
                <p>4.3 The Company explicitly prohibits providing its Services to, and does not accept customers from, the following regions: Afghanistan, Cuba, Iran, North Korea, Syria, the Russian Federation, Belarus, Crimea, Donetsk, Luhansk, Myanmar (Burma), the Central African Republic, China, Congo DR, Lebanon, Libya, Mali, Nicaragua, Somalia, Sudan, Venezuela, Yemen, Zimbabwe, and any other jurisdiction where such activities would be illegal under applicable laws and regulations. This list may be updated as necessary to comply with legal requirements.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>5. Your Account</Title>
                <p>5.1 The contract between user and the Company is established when user registers on the Company's Website. Registration is free and involves completing a registration form, during which certain user profile data is stored in the Company's database.</p>
                <p>5.2 The Company reserves the right to decline user registrations based on valid and objective reasons. These reasons may include, but are not limited to, violation of the Terms of Use, implementation of fraud prevention measures, doubts about the user's identity, suspicion of spamming activities, uncertainty regarding the user's age, fraudulent behaviour, or attempts to register in markets where the Company's services are unavailable.</p>
                <p>5.3 Each user may create only one user account, and they must delete any existing accounts before registering a new one. Users who have been blocked by the Company are not permitted to create multiple or repeat registrations.</p>
                <p>5.4 You bear sole responsibility for all activities carried out under your account. You agree not to utilize another user's account, username, or password at any point, nor to disclose your password to any third party, or engage in any actions that could compromise the security of your account. Promptly inform us of any unauthorized use of your account.</p>
                <p>5.5 If we have valid reasons to suspect a violation of these Terms or if the registration information provided by you is believed to be untrue, inaccurate, outdated, or incomplete, we reserve the right to terminate your user account and deny current or future access to any or all features.</p>
                <p>5.6 We disclaim any responsibility for any loss or damage incurred by you or any third party due to unauthorized access and/or use of your profile, or for any other reason.</p>
                <p>5.7 We reserve the right to suspend or terminate your access to the Services at any time, without any notice to you, including if you have failed to comply with any of the provisions of Terms of Use, or if activities occur on your account which would or might cause damage to or impair the Services or infringe or violate any third party rights (including intellectual property rights), or violate any applicable laws or regulations. Termination of your account also entails the termination of the license to use the Service, or any part thereof.</p>
                <p>5.8 We reserve the right to disable or deactivate user accounts that are inactive for more than 30 calendar days. This is a necessary housekeeping policy to ensure our infrastructure runs smoothly for the rest of our users. In the event of such termination, all data associated with such user account may be deleted. We are not obligated to provide you prior notice of such termination by email.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'} id='disclaimers'>6. User Conduct</Title>
                <p>6.1 Your access to and use of the Services are governed by these Terms as well as all applicable laws and regulations. You are prohibited from:</p>
                <ul className="terms__list">
                    <li>accessing or using the Services if you are not legally competent to agree to these Terms;</li>
                    <li>making unauthorized copies, modifying, adapting, translating, reverse engineering, disassembling, decompiling, or creating derivative works from the Services or any included content, including software and Digital Items, unless expressly permitted by these Terms or applicable law;</li>
                    <li>distributing, licensing, transferring, or selling any part of the Services or derivative works;</li>
                    <li>marketing, renting, or leasing the Services for a fee, or using the Services to advertise or solicit commercially, unless another directly prescribed herein;</li>
                    <li>using the Services for commercial, political, or unauthorized purposes without our written consent, including advertising, solicitation, or spamming;</li>
                    <li>interfering with or attempting to disrupt the Services or our Website, or bypassing any security measures we employ;</li>
                    <li>incorporating the Services or any part of them into another program or product;</li>
                    <li>using automated tools to collect information from or interact with the Services;</li>
                    <li>impersonating others or misrepresenting your affiliation with any person or entity, including misrepresenting content as originating from the Services;</li>
                    <li>intimidating, harassing, or promoting sexually explicit material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;</li>
                    <li>using another’s account or system without authorization from the Company;</li>
                    <li>using the Services in a way that conflicts with or undermines their intended purpose;</li>
                    <li>trading, selling, or duplicating Digital Items unless expressly permitted by the Company;</li>
                    <li>uploading or transmitting files that contain harmful software, unauthorized advertising, personal information, intellectual property infringements, defamatory content, or any material that is illegal, offensive, or intended to harm or provoke others;</li>
                    <li>providing answers or recommendations without proper licensing or qualifications;</li>
                    <li>restricting or inhibiting others' use of the Services or exposing the Company, Services, or users to harm or liability.</li>
                </ul>
                <p>6.2 We reserve the right to remove or disable your access to the Platform, including Digital Items, at our discretion, with or without prior notice, for any reason or no reason. This may include violations of these Terms or actions detrimental to the Services or users.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>7. Compliance Procedures</Title>
                <p>7.1 By using our Services, you agree to comply with the Terms and all other Company policies currently in effect. If you come across any content on the Website that you believe breaches the Terms, any other published policy, or is otherwise illegal, please report it to us via email at claims@artallai.com. Your report should include:</p>
                <ul className="terms__list">
                    <li>detailed explanation of why you believe the content is illegal;</li>
                    <li>exact location of the content, such as the specific URL(s) and any other relevant information needed to identify it based on the content type and hosting service;</li>
                    <li>your name and email address;</li>
                    <li>statement affirming your genuine belief that the information and claims you are providing are accurate and complete.</li>
                </ul>
                <p>7.2 We reserve the right to enforce these policies using various methods, including issuing warnings, removing content, or limiting or terminating a user’s access to the Website or Services. We may also permanently remove accounts for repeated violations or a single serious breach.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>8. Transactions</Title>
                <p>8.1 If you wish to sell any of Your Content through our Platform ("Transaction") you shall provide us with your current, valid, and accepted method of payment.</p>
                <p>8.2 All Transactions shall be made in euros (EUR) unless agreed otherwise.</p>
                <p>8.3 The Company will pay the agreed purchase price within 14 Business Days from the date of uploading Your Content to our Platform.</p>
                <p>8.4 If you wish to purchase any Digital Items through the Services, you may be asked to supply certain information relevant to your purchase, including but not limited to your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>
                <p>8.5 All prices listed on our Website are in euros (EUR) unless stated otherwise. </p>
                <p>8.6 Purchases must be executed through the designated payment methods available on the Website. By proceeding with a purchase, you affirm that you have the authority to utilize the chosen payment method. It is your responsibility to furnish accurate and comprehensive information necessary for the transaction, including billing address and payment particulars.</p>
                <p>8.7 We reserve the right to refuse or cancel any your purchase order at any time for any reasons including but not limited to Digital Items availability, errors in the description or price, error in your order, or other reasons.</p>
                <p>8.8 Instructions regarding the delivery of purchased goods through our Website will be sent to the email address linked to your account. Please note that the delivery process may take up to up to 14 calendar days from the moment of payment confirmation. We retain the right to cancel any orders that cannot be delivered, and in such cases, a full refund will be issued.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>9. Top-Up Service</Title>
                <p>You may have the option to top up your funds displayed in your account for easier and faster payment processing. The top up service allows you to perform top up with the use of your account functionality or through any other channels made available by us from time to time. Upon submission of your top up request, it will be channelled to a third-party Payment Service Provider for processing. Use of payment instruments via the top up service and processing of transactions by the Payment Service Provider are subject to the terms and conditions of that Payment Service Provider. The instructions for using the top up service may be also provided to you on our Website.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>10. Refunds</Title>
                <p>10.1 You qualify for a refund under the following conditions:</p>
                <ul className='terms__list'>
                    <li>If you encounter technical issues with a purchased Digital Item that prevent you from using or accessing it, please contact our customer support team via support@artallai.com. If we cannot resolve the issue within 5 Business Days, we will issue a refund;</li>
                    <li>If you spot unauthorized charges on your account related to purchases, reach out to our customer support team immediately. We will investigate, and if the charges are found to be unauthorized, we will issue a refund;</li>
                    <li>If there is a payment error, such as an overcharge or incorrect billing, contact our customer support team with evidence of the error, and we will promptly address the issue, which may include issuing a refund;</li>
                    <li>We may also issue a refund if we identify potentially fraudulent transactions or in other situations where a refund is necessary to protect our interests and ensure the integrity of our website.</li>
                </ul>
                <p>10.2 If you are a consumer and resident of the EEA, United Kingdom or Switzerland, you have a mandatory cancellation right to cancel an order when purchasing digital content (e.g Digital Items) within 14 days without giving any reason. The cancellation period will expire after 14 days from the day of the delivery of the Digital Items.</p>
                <p>10.3 Please be aware that you will lose this cancellation right if you provide prior express consent to begin the download of that content (e.g Digital Items) during the right of cancellation period and acknowledge that you will lose your cancellation right.</p>
                <p>10.4 To exercise the right of withdrawal, you must inform us via support@artallai.com.</p>
                <p>10.5 If you cancel an order, we shall reimburse to you all payments received from you without undue delay and in any event not later than 14 days from the day on which we are informed about your decision to withdraw from the contract, although this period may extend to up to 1 month depending on your payment provider. We will carry out such reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>11. Intellectual Property Rights</Title>
                <p>11.1 All information, data, text, graphics, images, software, code, metadata, links, audio and video files, online tools, and all other content comprising the Platform are the exclusive property of the Company or its licensors, unless explicitly stated otherwise. The Digital Items, Platform and its content are safeguarded by copyright laws and other applicable regulations.</p>
                <p>11.2 Certain names, graphics, logos, icons, designs, words, titles, or phrases showcased on the Platform may also be considered trade names, trademarks, or service marks registered elsewhere. The presence of these trademarks on the Platform does not imply any authorization for their use has been extended to you. Any unauthorized utilization, reproduction, or alteration of trademarks and/or any of the content contained herein may constitute a violation of applicable laws, as well as trademark and/or copyright statutes, and may result in legal repercussions.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>12. Indemnification</Title>
                <p>You agree to defend, indemnify, and hold us and our officers, members, managers, employees, and agents harmless from and against any and all claims, liabilities, damages, losses, demands, or expenses, including attorney’s fees and costs and expenses, arising out of or in any way connected with your use of the Platform, your breach or alleged breach of these Terms or your violation of any rights of any other person including other users of the Platform.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>13. Disclaimer</Title>
                <p>The Services, information and any other content available on the Platform are provided on an "as is" and "as available" basis. The Company does not make any representations or warranties of any kind, whether express or implied, regarding the operation of the Platform or the information, content, or materials available on it. You acknowledge and agree that your use of our Platform is at your own discretion.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>14. Limitation of Liability</Title>
                <p>14.1 To the fullest extent permitted by applicable law we (and our affiliates) shall not be liable to you or any third party for any lost profit or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from these Terms or your use of, or inability to use, the Service, Platform (including its content), or third-party ads, even if we have been advised of the possibility of such damages. This includes, without limitation, any damages resulting from the user's reliance on any information obtained from the Company, as well as damages arising from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission, or any failure of performance. Such damages may occur irrespective of whether they result from acts of God, communication failures, theft, destruction, fraud, or unauthorized access to the Company's records, programs, platforms, or services.</p>
                <p>14.2 Your access to, and use of, the Platform, and third-party ads are at your own discretion and risk, and you will be solely responsible for any damage to your computing system or loss of data resulting therefrom.</p>
                <p>14.3 Regardless of anything to the contrary contained herein, you agree that the aggregate liability of the company to you for any and all claims arising from the use of the app, content, service, or products, is limited to the amounts you have paid to the company for access to and use of the service. The limitations of damages set forth above are fundamental elements of the basis of the terms between the company and you.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>15. Governing Law and Disputes Resolution</Title>
                <p>15.1 These Terms are governed by the laws of Czech Republic, and any disputes will be subject to the laws of Czech Republic. Nothing in these Terms shall deprive you of the protection afforded to consumers by the mandatory rules of law of the country in which you live.</p>
                <p>15.2 If you are residing in EEA or Switzerland the online dispute resolution platform located at http://ec.europa.eu/consumers/odr can be used to resolve disputes between the Company and the user arising from these Terms.</p>
            </section>

            <section className="terms__group">
                <Title fontSize="24px" color={'#ED43DC'}>16. Miscellaneous</Title>
                <p>16.1 In the event that we fail to enforce any provision of these Terms, such failure shall not constitute a waiver of any future enforcement of that provision or of any other provision. Waiver of any part or sub-part of these Terms will not constitute a waiver of any other part or sub-part.</p>
                <p>16.2 You agree that no joint venture, partnership, employment, or agency relationship exists between you and the Company as a result of these Terms or your access to and use of the Website.</p>
                <p>16.3 These Terms constitute the entire agreement between you and us, and represent the complete and entire understanding of the parties with respect to the subject matter of this agreement. This agreement supersedes any other agreement or understanding between the parties, whether written or oral. In the event that any term or provision of this agreement is held by a court of competent jurisdiction to be unenforceable, then the remaining provisions of this agreement shall remain in full force and effect.</p>
                <p>16.4 The contractual relationship between us are effective until you or Company terminates them. You may terminate this Agreement at any time by discontinuing your use of the Platform. We may terminate this Agreement at any time and for any reason without prior notice to you, and accordingly, we may deny you access to the Platform. Termination of this Agreement will not affect any right or relief to which we are entitled at law or in equity. Upon termination of this Agreement, you must terminate all use of Platform and any information or materials that have been provided to you. The terms and conditions in this Agreement that by their nature and context are intended to survive any termination of this Agreement will survive such termination and will be fully enforceable thereafter.</p>
            </section>

        </div>
    );
};

export default TermsComp;