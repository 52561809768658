import React from 'react';
import PrivacyComp from "../components/TermsPrivacyCookie/PrivacyComp";

const Privacy = () => {
    return (
        <>
            <PrivacyComp/>
        </>
    );
};

export default Privacy;