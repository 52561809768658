import React from 'react';
import RegisterComp from "../components/Auth/RegisterComp";

const Register = () => {
    return (
        <div>
            <RegisterComp/>
        </div>
    );
};

export default Register;