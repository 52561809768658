import React from 'react';
import CookieComp from "../components/TermsPrivacyCookie/CookieComp";

const Cookie = () => {
    return (
        <>
            <CookieComp/>
        </>
    );
};

export default Cookie;