import React from 'react';
import ProfileComp from "../components/Profile/ProfileComp";


const Profile = () => {
    return (
        <div>
            <ProfileComp/>
        </div>
    );
};

export default Profile;