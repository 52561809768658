import React from 'react';
import LoginComp from "../components/Auth/LoginComp";

const Login = () => {
    return (
        <div>
            <LoginComp/>
        </div>
    );
};

export default Login;